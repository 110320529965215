<template>
  <div class="process">
      <div class="process__content" v-html="dataContent"></div>
      <div class="process__image">
          <!-- <img src="/img/gallery/technologie/procede.png" alt=""> -->
          <video autoplay loop muted poster="/img/gallery/technologie/procede.png">
              <source :src="`${$store.state.apiImages}${content.content.media?.data?.attributes?.url}`" type="video/mp4">
          </video>
      </div>
  </div>
</template>

<script>
import showdown from 'showdown';

export default {
    props:['content'],
    setup(props){
        let converter = new showdown.Converter({
            emoji: true,
            noHeaderId: true,
        }); 

        const dataContent = converter.makeHtml(props.content.content.text);

        return {dataContent}
    }
}
</script>

<style lang="scss">
@import "@/style/main";
.process{
    display: flex;

    @include respond(phone){
        flex-direction: column-reverse;
    }

    &__content{
        flex: 0 0 50%;
        margin-right: 3rem;

        @include respond(phone){
            margin-right: 0;
            margin-top: 4rem;
        }

        & ul{
              counter-reset: my-awesome-counter;
            list-style: none;
            padding-left: 4rem;
        }
        

      

        & li{
            counter-increment: my-awesome-counter;
            position: relative;
            padding-left: 1rem;
            list-style: none;

            &:not(:last-child){
                margin-bottom: 2rem;
            }

            &::before{
                $bullet-size: 3rem;
                content: counter(my-awesome-counter);

                color: $color-white;
                font-size: 1.6rem;
                font-weight: bold;
                position: absolute;
                left: calc(-1 * $bullet-size - 10px);
                line-height: $bullet-size;
                width: $bullet-size;
                height: $bullet-size;
                top: 50%;
                background: $color-primary;
                border-radius: 50%;
                text-align: center;
                box-shadow: 1px 1px 0 #999;

                transform: translateY(-50%);
                }
            
        }


    }
    &__image{
        flex: 1;
        & img{
            width: 100%;
            height: auto;
        }

        & video{
            width: 100%;
            height: auto;
        }
    }
}
</style>