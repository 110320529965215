<template>
    <carousel-nav v-if="actualites" :length="actualites.length"></carousel-nav>
    <actualite-carousel v-if="actualites" :data="actualites"></actualite-carousel>
</template>

<script>
import CarouselNav from '@/components/carousel/CarouselNav';
import ActualiteCarousel from '@/components/about/ActualiteCarousel';
import { onMounted, ref } from '@vue/runtime-core';

import {getData} from '@/composables/utils/getData';

export default {
    components:{
        ActualiteCarousel,
        CarouselNav
    },
    setup(){

        const actualites = ref(null);

         onMounted(async () => {
            actualites.value = (await getData('/actualites', {
                "sort":"id:desc",
                populate:"*"
            })).map(el => el.attributes);
           
        })
        return {actualites}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';

.actu{
    position: relative;
        background-color: $color-white;
        align-self: flex-start;
        box-shadow: $shadow;
        border-radius: 3px;
        &__bck{
            height: 23rem;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &__date{
            padding: 1rem 1.8rem;
            top: 1.5rem;
            right: -2rem;

            font-size: 1.8rem;
            letter-spacing: .1rem;

            background-color: $color-primary;
            color:$color-white;
            font-weight: 700;
            text-transform: uppercase;
            position: absolute;
    }

        &__content{
            background-color: $color-white;
            padding: 3rem;

            & h4{
                height: 6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                text-align: center;
                font-weight: 700;
                font-size: 2rem;
                color:$color-primary;
                margin-bottom: 2rem;
            }

            & p{
                text-align: justify;
            }
        }
}
</style>