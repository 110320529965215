<template>
  <div id="carousel-offer">
        <div v-for="o of data" class="offer__item">
            <div class="offer__img">
                <img :src="`${$store.state.apiImages}${o.image?.data.attributes?.formats.small.url}`" alt="">
            </div>
            <div class="offer__content">
                <h4>
                    {{o.name}}
                </h4>
                <p v-html="o.subtitle">

                </p>

                <div class="offer__technic">
                    <h5>
                        {{ $store.state.products.find(el=> el.productId === o.productId)?.content.find(it=> it.categoryId === 'capacity')?.title }}
                    </h5>
                    <ul>
                        <li v-for="l of $store.state.products.find(el=> el.productId === o.productId)?.content.find(it=> it.categoryId === 'capacity')?.list">
                            {{l.title}}
                        </li>
                    </ul>
                </div>
                <div class="offer__cta">
                    <router-link class="btn btn--primary" :to="{name:'Technology', params:{section:'products', product: o.productId}}"> {{$store.state.language === 'fr' ? "En savoir plus" :"Read more"}} </router-link>
                </div> 
            </div>
        </div>
    </div> 
</template>

<script>

import $ from 'jquery';

import { onMounted, watch } from '@vue/runtime-core';


export default {
    props:['data'],
    setup(props){
        watch(() => props.data, (n, o) => {
            // dataOffer.value = n;
            $("#carousel-offer").slick('unslick');
            setTimeout(setupCarousel, 100); //On met un delais pour permettre a la page de s'actualiser avant de générer le nouveau carousel;
        })

        
        onMounted(() => {
            setupCarousel();
        })

        const setupCarousel = () => {
            let numberTestimony = 1;

            if(screen === 'phone'){
                numberTestimony = 1;
            }
            // $("#carousel-offer").slick('unslick');

            $("#carousel-offer").slick({
                infinite: false,
                slidesToShow: numberTestimony,
                slidesToScroll: numberTestimony,
                rows:0,
                prevArrow: $("#prev"),
                nextArrow: $("#next"),
                autoplay: true,
                autoplaySpeed: 2000,
            });
        }
    }
}
</script>

<style>

</style>