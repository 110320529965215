<template>
      <div class="offer">
          <p>
              {{content.content.text}}
          </p>
          <carousel-nav :length="products.length"></carousel-nav>
          <solution-offer-carousel v-if="products.length" :data="products"></solution-offer-carousel>
          <div v-if="!products?.length"> No product found</div>
      </div>
</template>

<script setup>

import CarouselNav from '@/components/carousel/CarouselNav';
import SolutionOfferCarousel from '@/components/solutions/SolutionOfferCarousel';
import { ref, watch } from 'vue';

const props = defineProps(['content'])
const products = ref(props.content.content.products.data.map(el => el.attributes));

watch(()=> props.content.content.products.data, (n, o) => {
    products.value = n.map(el => el.attributes)
})
</script>

<style lang="scss">

@import '@/style/main';

.offer{
    display: flex;
    flex-direction: column;
    & p{
        margin-bottom: 2rem;
    }
    &__item{
        display: flex;
        flex-direction: row !important;

        @include respond(phone){
            flex-direction: column !important;
        }
        &:not(:last-child){


        }
    }

    &__cta{
        margin-top: 4rem;
        display: flex;
        align-self: flex-start;

        @include respond(phone){
                align-self: center;
            }
    }
    &__img{
        flex: 1;

        & img{
            width: 100%;
            height: auto;

            @include respond(phone){
                width: 50%;
                margin: 0 auto;
            }
        }
    }

    &__content{
        margin-top: 2rem;
        flex: 0 0 80%;
        margin-left: 4rem;

        @include respond(phone){
            margin-left: 0;
            margin-top: 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & h4{
            font-weight: 700;
            font-size: 2.4rem;
            color:$color-primary;
            margin-bottom: 1rem;
        }

        & h5{
            font-size: 1.6rem;
            font-weight: 400;
        }
    }

    &__technic{
        & h5{
            color:$color-primary;
            font-size: 2.4rem;
            text-transform: uppercase;
            margin-bottom: 2rem;
        }

        & li{
            @include listStyle;
        }
    }
}

</style>