<template>
  <div class="loader">
    <div class="loader__content">
        <img src="/img/logo/logo-c4water-gris.svg" alt="c4water logo">
        <!-- <h1>Page is loading ...</h1> -->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/style/main";
.loader {
  height: 100vh;
  background-image: linear-gradient(
    to bottom right,
    lighten($color-primary, 30),
    lighten($color-primary, 40)
  );

  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    // padding: 4rem;
    width: 100%;
    // background-color: $color-white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & h1 {
    //   margin-bottom: 3rem;
    }

    & img{
        width: 50%;
    }

    // & p {
    //   margin-bottom: 3rem;
    // }
    // & span {
    //   color: $color-primary;
    //   font-size: 8rem;
    //   font-weight: 4rem;
    // }
  }
}
</style>
