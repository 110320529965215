<template>
  <div class="ban">
      <div class="container ban__container">
          <div v-if="$store.state.languageChange" class="ban__lang u-hide-phone">
              <ul>
                  <li v-for="lang of langues">
                      <button class="ban__lang--btn" :class="{'ban__lang--btn-selected': $store.state.language === lang.locale ? true : false}" @click="changeLanguage(lang.locale)">{{lang.name}}</button>
                  </li>
              </ul>
          </div>
          <div class="ban__contact">
              <ul>
                  <li>
                      <a href="mailto:info@c4water.com">
                          <Svg icon="email" size="1.6" className="ban__contact--svg"></Svg>
                          info@c4water.com
                      </a>
                  </li>
                  <li class="">
                      <a href="tel:003268336048">
                          <Svg icon="phone" size="1.6" className="ban__contact--svg"></Svg>
                          +32 68 33 60 48
                      </a>
                  </li>
                  <li class="">
                      <a href="tel:0033660028518">
                          <Svg icon="phone" size="1.6" className="ban__contact--svg"></Svg>
                          +33 6 60 02 85 18
                      </a>
                  </li>
              </ul>
          </div>
      </div>
      
  </div>
</template>

<script>
import {languages} from '@/composables/languages';
import Svg from '@/components/utils/Svg';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from '@vue/reactivity';

export default {
    components:{
        Svg
    },
    setup(){
        const store = useStore();
        
        const langues = languages();
        console.log(langues);
        const route = useRoute();
        const routeName = ref(route.name);

        
        
        const changeLanguage = (lang) => {
            store.state.language = lang;

            //Router
            route.query.locale = lang;
            history.pushState({}, null,  route.path+'?locale='+lang)
            location.reload();

        }
 
        return {changeLanguage, langues}
    }

}
</script>

<style lang="scss">
@import '@/style/main';


.ban{
    background-color: $color-primary;
    color:$color-white;
    height: $ban-height;
    


    &__container{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

    
    }

    &__contact{
        margin-left: auto;
        &--svg{
            margin-right: .8rem;
        }
    }

    &__lang{

        &--btn{
            &-selected{
                 position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    top: calc(100% + .3rem);
                    left: 0;
                    width: 100% !important;
                    height: .1rem ;
                    background-color: currentColor;
            
                    transition: all .2s;
                }   
            }
        }

        & button{
            @include hoverUnderline
        }

    }

    ul{
        display: flex;
    }

    li{
        list-style: none;
        position: relative;

        

        &:not(:last-child){
            margin-right: 4rem;

            @include respond(small){
                margin-right: 3rem;
            }
            
            &::after{
                content:"";
                position: absolute;
                background-color: currentColor;

                left: calc(100% + 2rem);
                top: 50%;
                width: .7rem;
                height: .7rem;
                border-radius: 50%;

                transform:translate(-50%, -50%);

                @include respond(small){
                    left: calc(100% + 1.5rem);
                }
            }
        }

        a, button {
            @include buttonNoStyle;
            display: flex;
            align-items: center;

            @include respond(small){
                font-size: 1.2rem !important;
            }
        }
        
    }

    
}

</style>