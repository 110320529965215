<template>
<div ref="target">
  {{val}}
</div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted, watch } from '@vue/runtime-core';

import { useElementVisibility } from '@vueuse/core'
export default {
    props:['value'],
    setup(props){
        const val = ref(0);
        const time= 200;
        let loaded= false;
        const target = ref(null)
        const targetIsVisible = useElementVisibility(target)
        

        watch(targetIsVisible, (n, o) => {
          if(n && !loaded){
            loaded = true;
            incrementCounter(props.value);
          }
        })


        const incrementCounter = (value) => {

          if( value !== val.value){
            val.value += 5;
            setTimeout(() => {if(val.value < value) incrementCounter(value)}, 1);
          }
        }

        return {target, targetIsVisible, val};
    }
}
</script>

<style>

</style>