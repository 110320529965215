<template>
  <div class="home">
    <hero v-if="data" :content="data.hero"></hero>
    <section-loading v-else></section-loading>

    <section-component v-if="data?.equation" :content="data.equation"></section-component>

    <section-main v-if="data?.interactiveMap?.display" :data="data.interactiveMap">
      <div v-html="data.interactiveMapConfiguration"></div>
    </section-main>

    <!-- <section-main id="equation" color="primary" title="UN TRAITEMENT PAR ÉLECTROLYSE DE SEL" subtitle="Nos machines génèrent in-situ du chlore naturel">
      <home-equation></home-equation>
      <div class="home__cta">
        <router-link :to="{name:'Technology'}" class="home__cta--item btn btn--white">
          Notre technologie
        </router-link>
        <button @click="scrollTo('targets')" class="home__cta--item btn btn--primary">
          A qui s'adresse notre produit ?
        </button>
      </div>
    </section-main>

      <section-main id="figures" color="primary" title="CARE4WATER AROUND THE WORLD" subtitle="Rendre de l’eau potable, entretenir sa piscine ou traiter de l’eau pour l’industrie sont des problèmes auxquels répondent nos solutions.">
      <home-features></home-features>
  </section-main>
 -->
    <!-- <section-main  v-if="data.calculator" :data="data.calculator.header">
      <home-calculator></home-calculator>
      <home-advantages></home-advantages>
  </section-main> -->
    <section-component v-if="data?.features" :content="data.features"></section-component>
    <section-targets v-if="data?.targets" :content="data.targets"></section-targets>
    <section-component v-if="data?.impact" :content="data.impact"></section-component>
    <section-main v-if="data?.actu?.display" :data="data.actu">
      <actualite></actualite>
    </section-main>
    <!-- <section-main id="testimony" color="white" title="TÉMOIGNAGES" subtitle="Nos clients vous expliquent ce que l’électrolyse de sel leur a apporté.">
      <home-testimony></home-testimony>
  </section-main> -->
    <!-- <section-main v-if="data" :data="{title:'ils nous font confiance', color:'primary'}">
      <clients></clients>
  </section-main> -->
  </div>
</template>
<script>
// @ is an alias to /src
// import Header from '@/components/Header.vue'

import { getData } from "@/composables/utils/getData";

import Hero from "@/components/Hero";

import scrollTo from "@/composables/utils/scroll";

import HomeAdvantages from "@/components/home/HomeAdvantages";
import HomeCalculator from "@/components/home/HomeCalculator";
// import HomeEquation from '@/components/home/HomeEquation';
// import HomeFeatures from '@/components/home/HomeFeatures';
// import HomeMission from '@/components/home/HomeMission';
// import HomeTarget from '@/components/home/HomeTarget';
import HomeTestimony from "@/components/home/HomeTestimony";
import Clients from "@/components/Clients";

import Actualite from "@/components/about/Actualite.vue";

import SectionMain from "@/components/utils/SectionMain";
import SectionComponent from "@/components/utils/SectionComponent";
import SectionTargets from "@/components/utils/SectionTargets";
import SectionLoading from "@/components/utils/SectionLoading";

import { onMounted, ref } from "@vue/runtime-core";

export default {
  name: "Home",
  components: {
    Actualite,
    Hero,
    HomeAdvantages,
    HomeCalculator,
    Clients,
    // HomeEquation,
    // HomeFeatures,
    // HomeMission,
    // HomeTarget,
    HomeTestimony,
    SectionMain,
    SectionComponent,
    SectionLoading,
    SectionTargets,
  },
  setup() {
    const data = ref(null);

    onMounted(async () => {
      data.value = (
        await getData("/homepage", {
          "populate":"deep"
        })
      ).attributes;
      console.log('deak-data', data.value);
    });

    return {
      data,
      scrollTo,
    };
  },
};
</script>

<style lang="scss">
@import "@/style/main";

.home {
  &__cta {
    display: flex;
    justify-content: center;
    margin-top: 6rem;

    @include respond(phone) {
      flex-direction: column;
    }

    &--item {
      &:not(:last-child) {
        margin-right: 2rem;

        @include respond(phone) {
          margin-right: 0;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
</style>
