<template>
  <section :id="data?.sectionId" :class="`section--${data?.color}`">
    <div class="container">
      <div class="section__title">
        <h3 v-if="title" class="section-title">
          {{ title }}
        </h3>
        <h4 v-if="subtitle" class="section-subtitle">
          {{ subtitle }}
        </h4>
      </div>
      <slot></slot>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-core";
export default {
  props: ["data"],
  setup(props) {
    const title = ref(null);
    const subtitle = ref(null);

    onMounted(() => {
      assignData(props.data);
    });

    watch(
      () => props.data,
      (n, o) => {
        assignData(n);
      }
    );

    const assignData = (data) => {
      if (data.header) {
        title.value = data.header.title;
        subtitle.value = data.header.subtitle;
      } else {
        title.value = data.title;
        subtitle.value = data.subtitle;
      }
    };

    return { title, subtitle };
  },
};
</script>

<style lang="scss">
@import "@/style/main";
section {
  padding: 6rem 0;
}

.section {
  &__title {
    margin-bottom: 3rem;
  }
  &--white {
    background-color: $color-white;
  }
  &--primary {
    background-color: $color-background-primary;
  }
}
</style>
