import axios from "axios";
import store from "@/store";

export const getData = async (url, query) => {
  if (store.state.language) {
    const res = await axios({
      method: "GET",
      url,
      params: {
        locale: store.state.language,
        ...query
      },
    });

    if (res.status === 200) {
      return res.data.data;
    } else {
      console.log("error");
    }
  } else {
    return {};
  }
};
