<template>
  <div class="nav">
      <div class="container nav__container">
        <a href="/" class="nav__logo" >
            <img class="nav__logo--img" src="/img/logo/logo-c4water-gris.svg" alt="c4water logo">
        </a>
          <nav>
              <div class="nav__content u-hide-tab">
                  <ul class="nav__heading">
                          <li v-for="nav of navigation" @mouseleave="hideSubNav">
                              <router-link :to="{name: nav.name}" class="nav__heading--link" :class="selectedPage === nav.name ? 'nav__heading--link-selected' : ''" @mouseover="showSubNav(nav)">
                                  {{nav.title}} 
                              </router-link>
                              <div v-if="subNavigationShow.name === nav.name && subNavigationShow.display" class="nav__heading--sub">
                                    <router-link v-for="s of nav.subnav" :to="{name:nav.name, params:{section: s.page || s.target}}" class="nav__heading--sub-link">
                                        {{s.name}}
                                    </router-link>
                              </div>
                          </li>
                          <li>
                            <a href="https://m2web.talk2m.com/Care4Water" target="_blank" class="nav__heading--link" >
                                Intranet
                            </a>
                          </li>
                  </ul>
                  <ul class="nav__sub">
                      <li v-for="s of subNav">
                          <button v-if="s.target" @click="scrollTo(s.target)">{{s.name}}</button>
                          <router-link :to="{name:selectedPage, params:{page: s.page}}" v-else-if="s.page" :class="s.page === selectedSubPage ? 'nav__sub--selected' : ''">
                              {{s.name}}
                          </router-link>
                      </li>
                  </ul>
              </div>
              <button class="nav__menu u-show-tab" @click="toggleSide">
                  <div class="nav__menu--bar">
                      &nbsp;
                  </div>
              </button>
          </nav>
            <navigation-side :navigation="nav" :displaySide="displaySide" :hide="hideSide"></navigation-side>
      </div>
      
  </div>
</template>

<script setup>
import scrollTo from '@/composables/utils/scroll';

import Svg from '@/components/utils/Svg';
import NavigationSide from '@/components/navigation/NavigationSide';

import { onMounted, ref, watch } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps(['nav']);

// export default {
//     props:['nav'],
//     components:{
//         Svg,
//         NavigationSide
//     },
//     setup(props){
        const route = useRoute();
        const router = useRouter();

        const subNav = ref(null);
        const selectedPage = ref(null);
        const selectedSubPage = ref(null);

        const navigation= props.nav;
        console.log('deak-navigation', navigation)

        onMounted(() => {
            displaySubNav();
            if(route.params.page) selectedSubPage.value = route.params.page;
        })

        const subNavigationShow = ref({
            name:"Solutions",
            display: false
        })

        watch(() => route.name, (n, o) => {
            displaySubNav();
        })

        watch(() => route.params, (n,o) => {
            hideSubNav();
            if(n.page) selectedSubPage.value = n.page;
            else selectedSubPage.value = 'europe';
        })

        const displaySubNav = () => {
            selectedPage.value = route.name;
            if(navigation?.find(el=> el.name === route.name)){
                subNav.value = navigation.filter(el=> el.name === route.name)[0].subnav;
            }else{
                subNav.value = null;
            }
            
        }

        const hideSubNav = () => {
            subNavigationShow.value.display = false;
        }

        const showSubNav = (nav) => {
            if(nav.hover){
                subNavigationShow.value.name = nav.name;
                subNavigationShow.value.display = true;
            }

            else{
                subNavigationShow.value.display = false;
            }
        }


        const displaySide = ref(false);

        const hideSide = () => {
            displaySide.value = false;
        }
        const toggleSide = () => {
            displaySide.value = !displaySide.value;
        }

        

//         return { displaySide, navigation, hideSubNav,hideSide, scrollTo, selectedPage,selectedSubPage, showSubNav, subNav, subNavigationShow, toggleSide }
//     }
// }
</script>

<style lang='scss'>

@import '@/style/main';

.nav{
    height: $nav-height;
    background-color: $color-white;
    


    &__container{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    &__logo{
        @include buttonNoStyle;
        display: flex;
        align-items: center;

        &--img{
            height: 9rem;
            width: auto;
            @include respond(small){
                height: 7.5rem;
            }
        }
        
    }

    &__content{
        display: flex;
        flex-direction: column;
    }

    &__menu{
        margin-left: 2rem;
        @include buttonNoStyle;
        width: 4rem;
        
        height: 4rem;
        

        &--bar{
            position: relative;
            background-color: $color-primary;
            color:$color-primary;
            height: 3px;
            border-radius: 3px;

             &::before{
                content:"";
                position: absolute;
                left: 0;
                top: -1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
                border-radius: 3px;
                
            }
            &::after{
                content:"";
                position: absolute;
                left: 0;
                top: 1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
                 border-radius: 3px;
            }
        }

        &:hover > &--bar{
            &::before{
                top: -1.5rem;
            }
            &::after{
                top: 1.5rem;
            }
        }
    }

    &__heading{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & li{
            position: relative;
            list-style: none;
            &:not(:last-child){
                margin-right: 2rem;
            }
        }

        &--link{
            
            @include buttonNoStyle;
            @include hoverUnderline;
            color: $color-primary;
            font-weight: 700;
            font-size: 1.6rem;

            &-selected{
                &::after{
                    width: 100%;
                }
            }
        }

        &--sub{
            position: absolute;
            top: calc(100%);
            left: 0;
            z-index: 10000;
            display: flex;
            flex-direction: column;

            &-link{
                @include buttonNoStyle;
                  align-self: stretch;
                background-color: $color-white;
                padding: .5rem 1.2rem;
                border: 1px solid $color-primary;
                margin:0 !important;
                width: 20rem;
                font-weight: 700;

                &:not(:last-child){
                    border-bottom: none;
                }

                &:hover{
                    background-color: $color-background-primary;
                }
            }
            
        }
    }

    &__sub{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: .5rem;

        & li{
            list-style: none;
            &:not(:last-child){
                margin-right: 1.5rem;
            }
        }

        & a, button{
            @include buttonNoStyle;
            @include hoverUnderline;
            color:$color-primary;
            font-weight: 400;
            font-size: 1.3rem;

        }

        &--selected{
            &::after{
                width: 100% !important;
            }
        }
    }
}

nav{
    display: flex;
    align-items: center;
}



</style>