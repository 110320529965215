import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import axios from "axios";
axios.defaults.baseURL = window.location.href.includes('localhost') ? process.env.VUE_APP_CMS_LOCALHOST : process.env.VUE_APP_CMS;
//axios.defaults.baseURL = process.env.VUE_APP_CMS;

axios.defaults.headers["Authorization"] = "Bearer " + process.env.VUE_APP_STRAPIV4_TOKEN;

// axios.defaults.withCredentials = true;
// axios.defaults.headers["Accept-Language"] = "en";

createApp(App).use(store).use(router).mount("#app");
