<template>
    <div class="services">
        <div class="services__container">
            <div v-for="i of data" class="services__item">
                <h3>
                    {{i.title}}
                </h3>
                <div v-html="i.text" class="services__content">
                </div>
            </div>
        </div>
     
        <div class="services__img">
            <img :src="`${$store.state.apiImages}${content.image?.data?.attributes?.formats?.medium?.url || content.image?.data?.attributes?.url}`" alt="">
        </div>
    </div>


</template>

<script>
import showdown from 'showdown';
export default {
    props:['content'],
    setup(props){
         let converter = new showdown.Converter({
            emoji: true,
            noHeaderId: true,
        }); 
        
        const data = props.content.content;
        data.map(el => {
            el.text = converter.makeHtml(el.text);
        })


        return {data}
    }
}
</script>

<style lang='scss'>
@import '@/style/main';

.services{
    display: flex;
    &__item{
        &:not(:last-child){
         margin-bottom: 4rem;
        }

        & h3{
            margin-bottom: 1rem;
            text-transform: uppercase;
            color:$color-primary;
        }
    }

    &__img{
        margin-left: 3rem;
        & img{
            width: 100%;
            height: auto;
        }
    }

    &__container{
        flex: 0 0 70%;
    }
  
    &__content{

        & p{
            margin-bottom: 1rem;
        }
        & ul{
            margin: 1rem 0;
        }
        & li{
            @include listStyle;
        }
    }

}

</style>