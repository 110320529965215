<template>
  <div class="mission">
      <div v-for="i of data" class="mission__item">
          <div class="mission__content" :style="`flex: 0 0 ${i.attributes.flexSize}%`">
              <h3>
                  {{i.attributes.title}}
              </h3>
              <div v-html="i.attributes.text"></div>
          </div>
          <div v-if="i.attributes.image" class="mission__img">
              <img :src="`${$store.state.apiImages}${i.attributes.image?.data.attributes?.formats?.small?.url}`" alt="">
          </div>
      </div>
  </div>
</template>

<script>
import showdown from 'showdown';
import { ref } from '@vue/reactivity';

export default {
    props:['content'],
    setup(props){
        const data = ref(props.content.content.data);

        let converter = new showdown.Converter({
                emoji: true,
                noHeaderId: true,
            }); 

        data.value.map(el => el.attributes.text = converter.makeHtml(el.attributes.text))

        return {data}
    }
}
</script>

<style lang="scss">
@import '@/style/main';

.mission{
    display: flex;
    flex-direction: column;

    &__item{
        @include respond(phone){
            flex-direction: column-reverse;
        }

        background-color: $color-white;
        border: 1px solid $color-primary;
        box-shadow: $shadow;
        padding: 3rem;
        border-radius: 5px;
        display: flex;
        align-items: center;

        &:not(:last-child){
            margin-bottom: 2rem;
        }
    }

    &__content{
        // flex: 0 0 55%;
        margin-right: 3rem;

        @include respond(phone){
            margin-right: 0;
            margin-top: 3rem;
        }

        & h3{
            font-size: 2.4rem;
            text-transform: uppercase;
            letter-spacing: .1rem;
            color:$color-primary;
            margin-bottom: 1rem;
            @include respond(phone){
                font-size: 2rem;
                letter-spacing: 0;
            }
    }
        
        & ul{
            margin-left: 2rem;
        }

        & li{
            @include listStyle;
        }
        & p{
            text-align: justify;
            &:not(:last-child){
                margin-bottom: 1rem;
            }        }
    }

    &__img{
        flex: 1;

        & img{
            width: 100%;
            height: auto;
        }
    }
}

</style>