<template>
  <footer class="page__section">
    <div class="container footer">
      
       <div class="footer__logo">
        <img src="/img/logo/logo-c4water-white.svg" alt="LOGO c4water">
      </div>
      <ul class="footer__social">
        <li v-for="soc of social?.socialLink">
          <a :href="soc.href" target="_blank"><Svg :icon="soc.icon" size="3.4" className="footer__social--link"></Svg></a>
        </li>
      </ul>
      <div class="footer__content">
        <div v-for="col of content" class="footer__col">
          <h3> {{col.title}}</h3>
          <ul>
            <li v-for="i of col.list">
              <a v-if="i.type === 'link'" :href="i.href" class="footer__col--a">
                <Svg :icon="i.svg" size="3" ></Svg><span v-html="i.text"></span>
              </a>
              <router-link v-else-if="i.type='router-link'" :to="{name: i.to}" class="footer__col--router">{{i.text}}</router-link>
            </li>
          </ul>
        </div>
    </div>

    <div class="footer__bottom">
      <div class="footer__bottom--item">
        © Care4Water - All rights reserved
      </div>
    </div>
    </div>
   
  </footer>
</template>

<script>
import Svg from '@/components/utils/Svg';
import { onMounted } from '@vue/runtime-core';

export default {
  props:['social', 'content'],

  components:{
    Svg
  },
}
</script>

<style lang='scss'>
@import '@/style/main';



footer{
  background-color: $color-primary;
  color:$color-white;
}

.footer{
  position: relative;

  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;



  &__content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 3rem 0;

      @include respond(phone){
        flex-direction: column;

        padding: 4rem;
  }
}

  &__col{
    display: flex;
    flex-direction: column;

     @include respond(phone){
       align-items: center;
        &:not(:last-child){
          margin-bottom: 3rem;
        }
  }

    & h3{
      font-size: 2.4rem;
      text-transform: uppercase;
      letter-spacing: .1rem;
      margin-bottom: 1rem;

      
    }

    & ul{
      @include respond(phone){
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    & li{
      list-style: none;
      text-transform: uppercase;
      

      &:not(:last-child){
        margin-bottom:.5rem;
      }
    }

    & a{
      @include buttonNoStyle;
      font-size: 1.6rem;
    }

    &--router{
      @include hoverUnderline;
    }
    &--a{
      display: flex;
      align-items: center;
      margin-bottom:2rem;

      & span{
        margin-left: 1.2rem;
      }
    }
}


  &__social{
    position: absolute;
    display: flex;
    right: 0;
    top: 0;

    @include respond(phone){
      position: relative;
      margin-top: 2rem
    }

    &--link{
      @include buttonNoStyle;
      color: $color-white;
    }

    & li{
      list-style: none;
      &:not(:last-child){
        margin-right: 1.5rem;
      }
    }
  }

  &__logo{
    & img{
      height: 12rem;
    width: auto;
    }
    
  }
  
  &__bottom{
    align-self: stretch;
    padding-top: 1rem;
    border-top: 2px solid currentColor;
  }
}

</style>