<template>
   <div id="carousel-actu" class="carousel">
    <div v-for="actu of data" class="carousel__item actu">
        <div class="actu__bck" :style="`background-image:url('${$store.state.apiImages}${actu.img.data?.attributes?.formats?.medium ? actu.img.data?.attributes?.formats.medium.url :actu.img.data?.attributes?.formats.small.url}'); background-size:${actu.backgroundSize}`">
            &nbsp;
        </div>
        <div class="actu__date">
            {{actu.date}}
        </div>
        <div class="actu__content">
            <h4>
                {{actu.title}}
            </h4>
            <p>
                {{actu.description}}
            </p>
        </div>
        <div v-if="actu.cta" class="actu__cta">
            <a v-if="actu.target === 'link'" :href="actu.link" target="_blank" class="btn btn--primary">  {{actu.cta}}</a>
            <a v-else-if="actu.target === 'document'" :href="`${$store.state.apiImages}${actu.document.data?.attributes?.url}`" target="_blank" class="btn btn--primary">  {{actu.cta}}</a>
            <router-link v-else-if="actu.target === 'router'" :to="{path: actu.path}" class="btn btn--primary"> {{actu.cta}}</router-link>
        </div>
    </div>
    </div>
</template>

<script>
import {displaySize} from '@/composables/utils/displaySize';

import $ from 'jquery';
import slick from 'slick-carousel';
import { onMounted } from '@vue/runtime-core';

export default {
    props:['data'],
    setup(){
        
        const screen = displaySize();
        let numberTestimony = 3;

        if(screen === 'phone'){
            numberTestimony = 1;
        }
        onMounted(() => {
            $("#carousel-actu").slick({
                infinite: false,
                slidesToShow: numberTestimony,
                slidesToScroll: numberTestimony,
                rows:0,
                prevArrow: $("#prev"),
                nextArrow: $("#next"),
            });
        })

    }
}
</script>

<style>

</style>